import React, { useState } from "react"
import Rayon from "../../components/images/store"

import "./contact.css"

export default function Contact() {
  const [isSendingEmail, setIsSendingEmail] = useState(false)

  const [contact, setContact] = useState({
    name: '',
    email: '',
    subject: 'IBAHIS - Un nouveau message depuis le site web !',
    honeypot: '',
    message: '',
    replyTo: '@',
    accessKey: '779b90f0-5335-49a0-a287-76268491fcaf'
    // accessKey: '47425cfd-de10-420d-a629-120c4fe5ee73'
    // accessKey: '4ce75ed8-fb37-4386-80c7-9ed08532cb64'
  })

  const [response, setResponse] = useState({
    type: '',
    message: ''
  })

  const handleChange = e => setContact({ ...contact, [e.target.name]: e.target.value })

  const handleSubmit = async e => {
    e.preventDefault()
    
    if (isSendingEmail) { return }
    setIsSendingEmail(true)

    const formElement = e.target

    try {
      const res = await fetch('https://api.staticforms.xyz/submit', {
        method: 'POST',
        body: JSON.stringify(contact),
        headers: { 'Content-Type': 'application/json' }
      })

      const json = await res.json()

      if (json.success) {
        setResponse({
          type: 'success',
          message: 'Merci pour votre message. Nous vous recontacterons très vite !'
        })

        formElement.reset()
      } else {
        setResponse({
          type: 'error',
          message: json.message
        })
      }
    } catch (e) {
      console.log('An error occurred', e)
      setResponse({
        type: 'error',
        message: 'Oops, une erreur est survenue lors de l\'envoi, merci de réessayer plus tard.'
      })
    }

    setIsSendingEmail(false)
  }

  return (
    <section id="contact" className="section-wrapper">
      <h1 className="section-title">Contact</h1>
      <header>
        <aside>
          <Rayon />
        </aside>
        <p>
          "Nous sommes présents actuellement dans nos enseignes partenaires : Le tire-bouchon, La basse-cour, Le p’tit tire-bouchon aux Halles, Garden partie, La ferme de la quincé, Black temple food, Satio, La vieille auberge à Tinteniac, La galerie d’art Maison image, maison de quartier de villejean et bientôt dans vos commerces de proximité, vos bars préférés et en grandes surfaces."
          </p>
      </header>
      <main>
        <h2>Adresse Postale</h2>
        <p>
          SAS Wacos,<br />
            9 rue Louis Aragon, 35000 Rennes.
          </p>
        <form onSubmit={handleSubmit}>
          <h2>Nous envoyer un message</h2>

          <input type="text" name="honeypot" style={{ display: "none" }} />

          <section className="form-fields">
            <div className="form-section">
              <label htmlFor="name">Votre nom complet</label>
              <input type="text" name="name" id="name" onChange={handleChange} placeholder="Jean Martin" required />
            </div>

            <div className="form-section">
              <label htmlFor="email">Votre adresse email</label>
              <input type="email" name="email" id="email" onChange={handleChange} placeholder="jean.martin@gmail.com" required />
            </div>

            <div className="form-section">
              <label htmlFor="message">Votre message</label>
              <textarea name="message" id="message" onChange={handleChange} placeholder="Bonjour, ..." required></textarea>
            </div>

            <div className="form-section">
              <label htmlFor="phone">Votre numéro de téléphone (optionel)</label>
              <input type="tel" name="phone" id="phone" pattern="((\+)33|0|0033)[1-9](\d{2}){4}" onChange={handleChange} placeholder="0104030208" />
            </div>

            <div className="form-section">
              <button type="submit" disabled={isSendingEmail}>{isSendingEmail ? "Envoi..." : "Envoyer mon message"}</button>
            </div>
          </section>

          <p className={response?.type}>{response?.message}</p>
        </form>
      </main>
    </section>
  )
}